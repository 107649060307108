<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="sharedTemplates"
        sort-by="name"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-toolbar-title>{{ $t('templates|lavvira_templates') }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="account.accountType === 'god'"
              color="primary"
              dark
              class="mb-2"
              @click="createTemplate"
            >
              <v-icon
                left
                dark
              >
                mdi-file-plus-outline
              </v-icon>
              {{ $t('actions|add_new_template') }}
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.tags="{ item }">
          <div
            v-for="tag in item.tags"
            :key="tag"
          >
            {{ tag }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="account && account.accountType === 'god'"
            class="mr-2"
            @click="viewTemplate(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="account && account.accountType === 'god'"
            @click="deleteTemplateAction(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="account && !account.accountType === 'god'"
            @click="viewTemplate(item, 'view')"
          >
            mdi-magnify
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  data () {
    return {
      addTemplateDialog: false,
      deleteTemplateDialog: false,
      selectedTemplate: null,
      error: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      templates: state => state.templates.templates,
      account: state => state.account.account
    }),
    headers () {
      return [
        {
          text: this.$t('templates|template_name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$t('common|description'), value: 'tags', sortable: false },
        { text: this.$t('actions|actions'), value: 'actions', sortable: false, align: 'end' }
      ]
    },
    sharedTemplates () {
      return this.templates.filter(t => t.shared)
    },
    accountTemplates () {
      return this.templates.filter(t => !t.shared)
    }
  },
  created () {
    if (this.$route.query.openTemplate) {
      this.$router.replace({ 'query': null })
      this.addTemplateDialog = true
    }
    this.fetchTemplates(true)
  },
  methods: {
    ...mapActions({
      fetchTemplates: 'templates/fetchTemplates',
      addTemplate: 'templates/addTemplate',
      deleteTemplate: 'templates/deleteTemplate',
      addToast: 'toasts/addToast'
    }),
    createTemplate () {
      EventBus.$emit('dialog-add-template', {})
    },
    viewTemplate (template, type) {
      if (type && type === 'view') { this.$router.push({ path: `/templates/${template._id}?view=true` }) } else this.$router.push({ path: `/templates/${template._id}` })
    },
    async deleteTemplateAction (template) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('warning|delete_template')
      })
      if (!res) return
      try {
        await this.deleteTemplate(template)
        this.addToast({
          title: this.$t('message|template_delete_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('message|template_delete_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    }
  }
}
</script>
