<template>
  <div>
    <!-- <CaseModelsBar
      v-model="templateSearch"
      @selection="viewIsSelected"
    /> -->
    <!-- <v-container
      v-if="templates && templates.length"
      class="d-flex"
    >
      <v-row class="justify-start">
        <div
          v-for="caseForm in filteredCaseModelsByCategory"
          :key="caseForm._id"
          class="ma-2 mb-n6"
        >
          <CaseModelCard
            v-if="selection === 'cards'"
            :case-form="caseForm"
          />
        </div>
      </v-row>
    </v-container> -->
    <v-container v-if="templates && templates.length">
      <div>
        <LavviraTemplatesTable />
      </div>
    </v-container>
    <v-container v-else>
      no templates imported
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/EventBus'
// import CaseModelsBar from './CaseModelsBar.vue'
// import CaseModelCard from './CaseModelCard.vue'
// import ChangeViewFilter from '../../components/viewSelection/ChangeViewFilter.vue'
import moment from 'moment'
import LavviraTemplatesTable from './LavviraTemplatesTable.vue'
const createdAtFormat = date => moment(date).format('DD/MM/YYYY')

export default {
  components: { LavviraTemplatesTable },
  data () {
    return {
      selection: localStorage.getItem('preferedViewCaseModels') || 'cards',
      templateSearch: '',
      selectedByCategorie: ''
    }
  },
  computed: {
    ...mapState({
      templates: state => state.templates.templates
    }),
    _templates () {
      return this.templates.map((c) => {
        const data = {
          ...c,
          updatedAt: createdAtFormat(c.updatedAt),
          createdAt: createdAtFormat(c.createdAt)
        }
        return data
      })
    },
    filteredCaseModelsByCategory () {
      if (this.selectedByCategorie || (this.templateSearch && this.templateSearch.length)) {
        return this._templates.filter((c) => {
          return c.category.toLowerCase().includes(this.selectedByCategorie.toLowerCase()) && c.name.toLowerCase().includes(this.caseFormsSearch.toLowerCase())
        })
      } else if (this.selectedByCategorie === '' || (this.templateSearch && this.templateSearch.length)) {
        return this._templates.filter((c) => {
          return c.name.toLowerCase().includes(this.templateSearch.toLowerCase())
        })
      } else if (this.selectedByCategorie === 'none' || (this.templateSearch && this.templateSearch.length)) {
        return this._templates.filter((c) => {
          return c.name.toLowerCase().includes(this.templateSearch.toLowerCase())
        })
      }
      return this.templates
    }
  },
  created () {
    EventBus.$on('template-category-is-selected', this.onCategoryIsSelected)
  },
  methods: {
    viewIsSelected (value) {
      this.selection = value
    },
    onCategoryIsSelected (selectedItem) {
      this.selectedByCategorie = selectedItem
    }
  }
}

</script>
